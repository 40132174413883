const ID_TOKEN_KEY = "id_token";
const ID_REFRESH_KEY = "id_refresh";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const saveRefresh = token => {
  window.localStorage.setItem(ID_REFRESH_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const destroyRefresh = () => {
  window.localStorage.removeItem(ID_REFRESH_KEY);
};

export default {
  getToken,
  saveToken,
  destroyToken,
  saveRefresh,
  destroyRefresh
};
