import Vue from "vue";
import VueLodash from "vue-lodash";
import sumBy from "lodash/sumBy";
import map from "lodash/map";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import filter from "lodash/filter";
import orderBy from "lodash/orderBy";

Vue.use(VueLodash, {
  name: "customLodash",
  lodash: { sumBy, map, find, findIndex, filter, orderBy }
});
